import axios, {
  AxiosError,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import { API_BASE_URL, IS_ENCRYPTION_ENABLED } from "../constants";
import { AppLocale } from "../contexts/LocaleProvider";
import { parseJSON } from "../hooks/useSessionStorage";
import { appUrl } from "..";
import { encryption, encryptionResponse } from "./encryptionUtil";
import { NavigateFunction } from "react-router-dom";

console.log(API_BASE_URL);

export const customAxios = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

customAxios.interceptors.request.use(async (config) => {
  const locale = parseJSON<AppLocale>(sessionStorage.getItem("AppLocale"))
    ?.current.locale.code;
  config.headers = config.headers ?? {};
  config.headers.set("x-tollring-app-url", appUrl ?? "");
  config.headers.set("x-tollring-app-locale", locale ?? "");
  if (IS_ENCRYPTION_ENABLED) {
    config = await confirmEncryption(config);
  }
  config.url = encodeURI(config.url ?? "");
  return config;
});

const confirmEncryption = async (
  config: InternalAxiosRequestConfig<any>
): Promise<InternalAxiosRequestConfig<any>> => {
  if (
    config.method === "post" ||
    config.method === "put" ||
    config.method === "patch"
  ) {
    if (config.headers?.isEncrypted) {
      const originalPayload = { ...config.data };
      const modifiedPayload: encryptionResponse | string = await encryption(
        JSON.stringify(originalPayload)
      );
      config.data = modifiedPayload;
    }
  }
  return config;
};
export const setUpAuthAxiosInterceptors = (navigate: NavigateFunction) => {
  customAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error instanceof AxiosError) {
        if (
          (error.response && error.response.status === 503) ||
          error.code === "ERR_NETWORK"
        ) {
          navigate("/service-unreachable");
        }
      }
      return Promise.reject(error);
    }
  );
};
