import { useEffect, useState } from "react";
import useLocale from "../hooks/useLocale";
import { Dictionary } from "../types/Dictionary";
import Swal from "sweetalert2";

const ServiceUnreachable: React.FC = () => {
  const localeCtx = useLocale();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "serviceunreachable"
    ]
  );

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        setTranslationsLoading(true);
        const resp = await localeCtx?.setComponentTranslations(
          "serviceunreachable"
        );
        setTranslations(resp);
      } catch (err) {
        setTranslations(
          localeCtx?.selectedLocale?.previous.componentTranslations[
            "serviceunreachable"
          ]
        );
        localeCtx?.setPreviousAppLocale("serviceunreachable");
        if (localeCtx?.localeSwitchFailed) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Couldn't Switch Language",
          });
        }
      } finally {
        setTimeout(() => {
          setTranslationsLoading(false);
        }, 100);
      }
    };

    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "serviceunreachable"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  //#region Locale Translation Methods
  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };
  //#endregion Locale Translation Methods

  return (
    <div
      style={{ height: "100vh" }}
      className="listMobile d-flex align-items-center justify-content-center bg-white-14"
    >
      <div>
        <div
          className="p-b-10"
          style={{ fontSize: "60px", textAlign: "center", opacity: ".7" }}
        >
          <i className="bi bi-exclamation-triangle text-primary"></i>
        </div>
        <p
          style={{ textAlign: "center" }}
          className="fs-22 font-weight-semi p-10"
        >
          {translationsLoading
            ? "Service Unavailable"
            : fetchLabelKeyTranslation(
                "ServiceUnavailableText",
                "Service Unavailable"
              )}
        </p>
        <ul>
          <li className="fs-16 p-b-10">
            {translationsLoading
              ? "The server encountered an error and could not complete your request. Please try again later."
              : fetchLabelKeyTranslation(
                  "ServiceUnavailableReasonTextOne",
                  "The server encountered an error and could not complete your request. Please try again later."
                )}
          </li>
          <li className="fs-16">
            {translationsLoading
              ? "Try checking your connection."
              : fetchLabelKeyTranslation(
                  "ServiceUnavailableReasonTextTwo",
                  "Try checking your connection."
                )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ServiceUnreachable;
