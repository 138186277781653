import { Branding } from "../types/branding/Branding";
import { customAxios } from "../utils/customAxios";

const brandingService = {
  fetchBranding: async (appUrl: string): Promise<Branding> => {
    try {
      const response = await customAxios.get(
        `branding/brandingbyappurl?appUrl=${window.btoa(appUrl)}`
      );
      const branding: Branding = response.data;

      return branding;
    } catch (err) {
      throw err;
    }
  },
};

export default brandingService;
